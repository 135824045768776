import { User } from '@creator/sdk/modules/account/account.model';
import { DomainConfig } from '@creator/sdk/modules/config/config.model';
import { DomainsSymbols } from '@creator/sdk/modules/public-settings/public-settings.model';
import { Token } from '@creator/sdk/modules/token/token.model';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import { isObjectEmpty } from '@creator/ui/utils/object-utils';
import store from '@src/store';
import { getPaginatedPostCollectionAtoms, postsAtom } from '@src/store/posts/posts';
import { jotaiStore } from '@src/store/store';

export interface SSRData {
    i18n: Record<string, string>;
    domainConfig: DomainConfig;
    domainSymbols: DomainsSymbols;
    tokens?: Record<Token['tokenName'], Token>;
    tokensAbout?: Record<Token['tokenName'], string>;
    users?: Record<User['id'], User>;
    whitelistedUsers?: Record<Token['tokenName'], User['id'][]>;
    posts?: Record<Post['id'], Post>;
    pinnedPosts?: Record<Post['id'], Post>;
    metaParams: Record<string, string>;
}


export function processSSRData() {
    const ssrData = window.__SSR_DATA__;
    const { tokens = {}, tokensAbout, users, posts, pinnedPosts, whitelistedUsers, domainSymbols } = ssrData;

    if (posts && !isObjectEmpty(posts)) {
        jotaiStore.set(postsAtom, posts);
        const { idsAtom, hasMoreAtom, lastDocAtom } = getPaginatedPostCollectionAtoms('partner');
        const lastPost = Object.values(posts)[Object.values(posts).length - 1];
        const { tokenName } = lastPost;
        // const compositeKey = `${tokenName}-${flairId || 'all'}`;
        const compositeKey = `${tokenName}-all`;
        const postIds = Object.keys(posts);

        jotaiStore.set(idsAtom, { [compositeKey]: postIds });
        jotaiStore.set(lastDocAtom, { [compositeKey]: lastPost.id });
        jotaiStore.set(hasMoreAtom, { [compositeKey]: true });
        store.getActions().upvote.setPosts(posts);
    }
    if (pinnedPosts && !isObjectEmpty(pinnedPosts)) {
        const randomPost = Object.values(pinnedPosts)[0];
        const { tokenName } = randomPost;
        jotaiStore.set(postsAtom, pinnedPosts);
        const compositeKey = `${tokenName}-all`;
        const pinnedPostIds = Object.keys(pinnedPosts);
        const { idsAtom } = getPaginatedPostCollectionAtoms('pinned')
        jotaiStore.set(idsAtom, { [compositeKey]: pinnedPostIds });
        store.getActions().upvote.setPosts(pinnedPosts);
    }
    if (tokens) store.getActions().token.setTokens(tokens);
    if (tokensAbout) store.getActions().token.setTokensAbout(tokensAbout);
    if (users) store.getActions().user.setUsersDict(users);
    if (whitelistedUsers) store.getActions().token.setTokenPartners(whitelistedUsers);
    if (domainSymbols) store.getActions().publicSettings.set({ key: 'domainsSymbols', value: domainSymbols });
    return ssrData;
}