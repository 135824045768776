import React, { FC, Fragment, memo, useEffect, useRef } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '@src/model/hooks';
import Card from '@creator/ui/components/Card/CardV2';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { isPostDeleted, isPendingPost, isResponsePost, isPendingTranscriptPost } from '@src/model/upvote/helpers';
import PostStatsAndReactions from '../PostStatsAndReactions/PostStatsAndReactions';
import storageService from '@src/services/storage-service';
import { isCurrentPathFirstEverVisitPath } from '@src/utils/url-utils/url-utils';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { canViewDeletedPostDetails, canViewPendingContent, canViewPendingTranscriptContent } from '@src/model/upvote/permission';
import PendingPostApproveDelete from '@src/components/Post/PendingPostList/PendingPostApproveDelete/PendingPostApproveDelete';
import PostCardHeader from '../PostCard/PostCardHeader';
import { isPerrmisioned } from '@src/model/user/permission';
import { convertFirebaseTimestampToDate } from '@creator/ui/utils/date-utils';
import { pushToDataLayer } from '@src/services/gtmService';
import Hr from '@creator/ui/components/Hr/Hr';
import PostTitle from './PostTitle';
import PostModScore from './PostModScore';
import PostContent from './PostContent';
import { ReactLazyPreload } from '@creator/ui/utils/react';
import { cn } from '@creator/ui/utils/ui';
import RenderOnViewportEntry from '@creator/ui/components/RenderOnViewportEntry/RenderOnViewportEntry';

const CommentsBox = ReactLazyPreload(() => import('@src/basic-components/CommentsBox/CommentsBox'));
const PostResponses = ReactLazyPreload(() => import('@src/components/Post/PostResponses/PostResponses'));
const PostEmbeddedByResponse = ReactLazyPreload(() => import('@src/components/Post/EmbeddedPost/PostEmbeddedByResponse'));
const RecommendedPostList = ReactLazyPreload(() => import('@src/components/Post/RecommendedPostList/RecommendedPostList'));
const SuperComments = ReactLazyPreload(() => import('@src/components/SuperComment/SuperComments'));

export interface PostViewProps {
    className?: string;
    postId: string;
    tokenName: string;
    loadCommentId?: string;
    loadReplyId?: string;
    showRecommendedPostList?: boolean;
}

const PostView: FC<PostViewProps> = props => {
    const { tokenName, postId, className, loadCommentId } = props;
    const { t } = useTranslation();

    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);
    const response = useStoreState(state => state.response.getResponse(postId), storeEqualityFn);

    const isFirstSetReferredBy = useRef(true);
    // Create a ref for the CommentsBox container.
    const commentsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!post) return;
        pushToDataLayer({
            event: 'article_view',
            primaryAuthorId: post.publisherName,
            primaryAuthor: post.publisherProfileName || post.publisherName,
            originalPublishDate: convertFirebaseTimestampToDate(post.createdAt).toISOString()
        });
    }, [postId]);

    useEffect(() => {
        if (!post) return;
        if (!isFirstSetReferredBy.current) return;

        setReferredBy();
        isFirstSetReferredBy.current = false;
    }, [post]);

    // New effect: if loadCommentId exists, scroll to the CommentsBox element on mount.
    useEffect(() => {
        if (loadCommentId && commentsRef.current)
            commentsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }, [loadCommentId]);

    function setReferredBy(): void {
        if (!post) return;

        const referredBy = storageService.localStorage.get('referredBy');
        if (referredBy) return;

        if (!isCurrentPathFirstEverVisitPath()) return;

        storageService.localStorage.set('referredBy', post.publisherId);
    }

    function renderPendingApproveOrDelete() {
        if (!post) return null;
        if (!isPendingPost(postId) || !canViewPendingContent(tokenName)) return null;

        return <PendingPostApproveDelete postId={post.id} className="" />;
    }

    function renderContent() {
        if (!post) return <Spinner className="mx-auto mt-10 mb-10 " />;

        // Hide post if viewer isnt a moderator or the post owner
        if (isPostDeleted(postId) && !canViewDeletedPostDetails(tokenName, postId) ||
            isPendingPost(postId) && !canViewPendingContent(tokenName) ||
            isPendingTranscriptPost(postId) && !canViewPendingTranscriptContent(postId))
            return <Text className="block text-sm">{t(`postStatus${post.status}`)}</Text>;

        return <PostContent postId={postId} tokenName={tokenName} />;
    }

    function renderStatsAndReactions() {
        if (!post) return null;
        if (isPostDeleted(postId)) return null;
        if (isPendingPost(postId)) return null;

        return (
            <Fragment>
                <Hr className="w-auto mt-4 mb-4 -mx-4 lg:-mx-4" />
                <div className="mt-4 text-sm font-bold leading-none">
                    <PostStatsAndReactions tokenName={tokenName} className="" postId={postId} isDisabled={isPendingPost(postId)} />
                </div>
            </Fragment>
        );
    }

    function renderComments() {
        if (!post || isPendingPost(postId)) return null;

        // Wrap CommentsBox in a div with a ref to allow scrolling.
        return (
            <CommentsBox replyId={props.loadReplyId} commentId={props.loadCommentId} tokenName={props.tokenName} postId={postId} />
        );
    }

    function renderPostEmbeddedByResponse() {
        if (!response || !response.parent || response.parent === 'root') return null;

        return <PostEmbeddedByResponse className="mt-4 text-sm" responseId={response.id} />;
    }

    function renderResponses() {
        if (!post || !isResponsePost(postId)) return;

        return <PostResponses tokenName={tokenName} postId={postId} />;
    }

    function renderSuperComments() {
        if (!post || isPendingPost(postId)) return null;

        return <SuperComments tokenName={tokenName} postId={postId} />;
    }

    function renderDeletedBy() {
        if (!post) return null;
        if (!isPostDeleted(postId)) return null;

        return (
            <Text className="block mt-4 text-sm font-bold lg:mt-6">{t(`postStatus${post.status}`)}</Text>
        );
    }

    function renderRecommendedPostList() {
        const { showRecommendedPostList = false } = props;
        if (!showRecommendedPostList) return null;

        return <RecommendedPostList excludePostIds={[postId]} tokenName={tokenName} />;
    }

    const renderPostCardHeader = () => {
        if (!post) return null;

        const { createdAt, tokenName, publisherName, createdByWhitelistedUser, publisherProfileName } = post;

        const _props = {
            postId,
            className: 'bg-transparent dark:bg-transparent',
            postBasicInfoProps: {
                className: '',
                publisherName,
                createdAt: createdAt,
                tokenName: tokenName,
                publisherProfileName: createdByWhitelistedUser ? publisherProfileName : '',
                showPartnerLabel: createdByWhitelistedUser
            }
        };

        return (
            <PostCardHeader {..._props} />
        );
    };

    const renderModScore = () => {
        if (!post || !post.moderationScore || !post.moderationContent) return null;
        if (!isPerrmisioned(tokenName, 'hideContent')) return null;
        if (post.createdByWhitelistedUser) return null;

        return <PostModScore moderationContent={post.moderationContent} moderationScore={post.moderationScore} />;
    };

    return (
        <Card className={cn('pt-0 lg:pt-0', className)}>
            {renderPostCardHeader()}
            <div className={`px-4 ${isPostDeleted(postId) ? 'opacity-20' : ''}`}>
                <PostTitle postId={postId} tokenName={tokenName} />
                {renderPostEmbeddedByResponse()}
                {renderModScore()}
                {renderContent()}
                {renderStatsAndReactions()}
                {renderPendingApproveOrDelete()}
            </div>
            {renderDeletedBy()}
            <div ref={commentsRef}>
                <RenderOnViewportEntry className="px-4 mt-4 space-y-4">
                    {renderSuperComments()}
                    {renderResponses()}
                    {renderComments()}
                    {renderRecommendedPostList()}
                </RenderOnViewportEntry>
            </div>
        </Card>
    );
};

export default memo(PostView, propsEqualityFn);
