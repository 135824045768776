import React, { FC, useLayoutEffect, memo } from 'react';
import { useStoreState } from '@src/model/hooks';
import storageService from '@src/services/storage-service';
import { getUserId, isUserWhitelisted } from '@src/model/user/helpers';
import PartnerList from '@src/components/Token/PartnerList/PartnerList';
import TopicsBar from './TopicsBar';
import TopCenterAd from '../TopCenterAd';
import PostsTabPartnerPosts from './PartnerPosts';
import { cn } from '@creator/ui/utils/ui';
import CreateFeedItemInputWrapper from './CreateFeedItemInputWrapper';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import BtmStickyAd from '../BtmStickyAd';

const CommunitySection = React.lazy(() => import('./CommunitySection'));

export interface PostsTabProps {
    tokenName: string;
    className?: string;
    flairId: string;
}

const TokenPagePostsTab: FC<PostsTabProps> = props => {
    const { tokenName, flairId, className = '' } = props;

    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);

    useLayoutEffect(() => {
        return () => {
            storageService.memoryStorage.set(`${tokenName}_postListScrollPosition`, window.scrollY.toString());
        };
    }, []);

    function renderCreateFeedItemInput() {
        if (!isLoggedIn || !isUserWhitelisted(getUserId(), tokenName)) return null;

        return <CreateFeedItemInputWrapper tokenName={tokenName} />;
    }

    function renderPartnerPosts() {
        if (!token) return null;
        return <PostsTabPartnerPosts tokenName={tokenName} flairId={flairId} />;
    }

    return (
        <div className={cn('space-y-4', className)}>
            <div className="container space-y-4">
                <PartnerList className="h-[75px] lg:h-[102px]" tokenName={tokenName} />
                <TopCenterAd tokenName={tokenName} />
                {renderCreateFeedItemInput()}
                <TopicsBar tokenName={tokenName} flairId={flairId} />
                {renderPartnerPosts()}
            </div>
            <CommunitySection tokenName={tokenName} flairId={flairId} />
            <BtmStickyAd tokenName={tokenName} variant="mobile" />
            <BtmStickyAd tokenName={tokenName} variant="desktop" />
        </div>
    );
};

export default memo(TokenPagePostsTab);
