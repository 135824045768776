import Button from '@creator/ui/components/Button/ButtonV2';
import Text from '@creator/ui/components/Text/Text';
import { cn } from '@creator/ui/utils/ui';
import PostCard from '@src/components/Post/PostCard/PostCard';
import { isMobile } from '@src/utils/utils';
import React, { FC, Fragment, memo, useEffect, useState } from 'react'; // Added useState, useMemo
import { useTranslation } from 'react-i18next';
import DesktopMidRightAd from '../DesktopMidRightAd';
import { getYahooAdByLocation, getYahooAdTargetingByTokenName } from '@src/model/config/helpers';
import GoogleAdSlot, { GooglaAdSlotSize } from '@src/components/GoogleAdSlot/GoogleAdSlot';
import { usePaginatedPosts } from '@src/store/posts/hooks/usePaginatedPosts';
import MainPostCard from './MainPost';

interface PostsTabPartnerPostsProps {
    tokenName: string;
    flairId?: string;
    className?: string;
}

const BATCH_SIZE = 6; // Define the batch size

const PostsTabPartnerPosts: FC<PostsTabPartnerPostsProps> = props => {
    const { tokenName, flairId, className = '' } = props;
    const { postIds, loadMore, status, hasMore } = usePaginatedPosts({ type: 'partner', tokenName, flairId, fetchLimit: 10 });
    const { postIds: pinnedPostsIds, loadMore: loadPinnedPosts } = usePaginatedPosts({ type: 'pinned', tokenName, flairId });

    const { t } = useTranslation();

    // State to control how many posts (besides the main one) are displayed
    const [displayLimit, setDisplayLimit] = useState<number>(BATCH_SIZE);

    // --- Combine Posts ---
    const combinedPosts = Array.from(new Set([...pinnedPostsIds, ...postIds]));
    const mainPostId = combinedPosts.length > 0 ? combinedPosts[0] : null;
    const remainingPosts = combinedPosts.slice(1);
    // Posts to actually render in the grid (excluding the main post)
    const postsToDisplay = remainingPosts.slice(0, displayLimit);

    // --- Initial Loading Effects ---
    useEffect(() => {
        if (pinnedPostsIds.length > 0 || flairId) return;
        loadPinnedPosts();
    }, [flairId, tokenName]);

    // // Initial load for partner posts if none are loaded yet
    useEffect(() => {
        if (postIds.length > 0) return; // No need to load more if we already have posts
        if (status === 'loading') return; // No need to load more if we are already loading

        loadMore();
    }, [tokenName, flairId]);

    const handleLoadMore = () => {
        const newLimit = displayLimit + BATCH_SIZE;
        // Check if we need to fetch more data from the server
        // We need to fetch if the new limit is greater than the number of posts we currently have (excluding main)
        // AND if the server indicates there are potentially more posts (`hasMore`)
        if (newLimit > remainingPosts.length && hasMore && status !== 'loading')
            loadMore();

        // Update the display limit regardless
        setDisplayLimit(newLimit);
    };

    function renderAd(i = 0) {
        const location = isMobile() ? 'main_mw_mid_center' : 'main_dt_mid_center_card';
        const yahooAd = getYahooAdByLocation(location);
        if (!yahooAd) return null; // Return null if no ad config found

        const sizes: GooglaAdSlotSize = isMobile() ? [300, 250] : [300, 600];
        const { adUnitPath, divId } = yahooAd;
        return (
            <div key={`ad-${i}`} className="py-2">
                <GoogleAdSlot
                    targeting={getYahooAdTargetingByTokenName(tokenName)}
                    className="mx-auto"
                    adUnitPath={adUnitPath}
                    sizes={sizes}
                    divId={`${divId}-${i}`} // Ensure unique divId per ad instance
                    index={i}
                />
            </div>
        );
    }

    function renderLoadMoreButton() {
        // Show button if:
        // 1. There are more posts available locally than currently displayed OR
        // 2. The server indicates there are more posts to fetch (`hasMore`)
        const canShowMoreLocally = displayLimit < remainingPosts.length;
        const showButton = canShowMoreLocally || hasMore;

        if (!showButton) return null;

        return (
            <Button
                isLoading={status === 'loading'} // Show loading state when fetching
                size="md"
                className="w-full"
                onClick={handleLoadMore} // Use the new handler
            >
                <Text>{t('partnerPostList.loadMoreButton')}</Text>
            </Button>
        );
    }

    // Renders a single post card in the grid
    function renderGridPost(postId: string, index: number) {
        const displayIndex = index + 1;
        return (
            <Fragment key={postId}>
                <PostCard
                    tokenName={tokenName}
                    className={cn('bg-transparent dark:bg-transparent')}
                    postId={postId}
                />
                {/* Render an ad every 3rd *rendered* post card in the grid */}
                {isMobile() && displayIndex % 3 === 0 && renderAd(displayIndex / 3)}
            </Fragment>
        );
    }

    // Renders the main (first) post
    function renderMainPost() {
        if (!mainPostId) return null; // Handle case where there are no posts at all

        return <MainPostCard key={mainPostId} tokenName={tokenName} postId={mainPostId} />
    }

    function renderEmptyState() {
        // Show empty state only if loading succeeded and there are *no* posts (neither pinned nor partner)
        if (status === 'success' && combinedPosts.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center py-4 space-y-2">
                    <Text className="text-xl text-gray-900 dark:text-gray-50">{t('partnerPostList.emptyState.title')}</Text>
                    <Text enableMultiline={true} className="text-center text-gray-500 dark:text-gray-500">{t('partnerPostList.emptyState.subtitle')}</Text>
                </div>
            );
        }
        return null; // Don't show empty state if loading, failed, or posts exist
    }

    // Renders the main grid container
    function renderPostsGrid() {
        // Don't render the grid if there's no main post yet (e.g., during initial load)
        if (!mainPostId) return null;

        return (
            <div className="grid gap-x-5 gap-y-4 lg:grid-cols-3">
                {renderMainPost()}
                <div className="hidden lg:block">
                    {renderDesktopAd()}
                </div>
                {postsToDisplay.map(renderGridPost)}
            </div>
        );
    }

    function renderDesktopAd() {
        if (isMobile()) return null;
        return <DesktopMidRightAd key="desktop-ad" tokenName={tokenName} />;
    }

    return (
        <div className={cn('space-y-4', className)}>
            {renderEmptyState()}
            {renderPostsGrid()}
            {renderLoadMoreButton()}
        </div>
    );
};

export default memo(PostsTabPartnerPosts);