import React, { FC, memo, useCallback, useContext, useState } from 'react';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import TextareaInput, { TextareaInputProps } from '@creator/ui/components/Form/TextareaInput/TextareaInput';
import Icon from '../Icon/Icon';
import { useStoreActions, useStoreState } from '@src/model/hooks';
import { cn } from '@creator/ui/utils/ui';
import { useHistory } from 'react-router-dom';
import { getUserId, isUserJoinedToToken } from '@src/model/user/helpers';
import { UseModal } from '@src/hooks/use-modal';
import { ModalContext } from '@src/context/modal-context';
import JoinButtonModal from '@src/components/JoinButtonModal/JoinButtonModal';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { ensurePostUrl } from '@src/model/upvote/helpers';

export interface CommentTextareaProps {
    className?: string;
    placeholder?: string;
    postId: string;
    tokenName: string;
}

const MAX_CHARS_IN_COMMENT = 2000;

const QuickCommentInput: FC<CommentTextareaProps> = props => {
    const { postId, tokenName } = props;

    const { t } = useTranslation();
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const [value, setValue] = useState('');
    const [isSending, setIsSending] = useState(false);
    const history = useHistory();
    const { handleModal } = useContext<UseModal>(ModalContext);

    const createPostComment = useStoreActions(actions => actions.upvote.createPostComment);

    const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    }, []);

    const onClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }, []);

    function mapError(error): void {
        if (error.message.includes('banned')) return alert(t('errors.bannedFromCommunity'));
        if (error.message.includes('Bad nonce')) return alert(t('badNonceErrorMessage'));
        if (error.message.includes('is hidden by domain admin')) return alert(t('unableToUndelete'));
        if (error.message.includes('rate limit')) return alert(t('rateLimitError'));
        if (error.message.includes('is hidden')) return alert(error.message);
        // if (error.message.includes('is hidden')) return alert(t('cantCommentPostHidden'));
        alert(t('generalErrorMessage') + ' ' + error.message);
    }

    async function onSubmit() {
        if (isLoggedIn && !isUserJoinedToToken(tokenName))
            return handleModal(<JoinButtonModal tokenName={tokenName} />);

        setIsSending(true);
        try {
            const comment = await createPostComment({
                tokenName,
                postId,
                replyToCommentId: '',
                publisher: getUserId() ?? '',
                content: value
            });

            history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}/comment/${comment.commentId}`);
            setIsSending(false);
        } catch (error) {
            mapError(error);
            setIsSending(false);
            console.error(error);
        }
    }


    function getClassName(): string {
        const { className = '' } = props;
        let base = `text-sm flex-1 rounded-lg px-3 transition group cursor-pointer flex items-center 
        border border-gray-200 dark:border-gray-600 hover:border-gray-800 dark:hover:border-gray-400 
        focus-within:border-gray-400 dark:focus-within:border-gray-400 `;
        if (value) base = `${base} border-gray-400 dark:border-gray-400`;

        return cn(base, className);
    }

    function getCommentButtonClassName(): string {
        const base = 'flex p-1 text-lg cursor-pointer';
        if (value) return `${base} text-gray-900 dark:text-gray-50`;
        return `${base} text-gray-400 dark:text-gray-600`;
    }


    function renderCommentButton() {
        return (
            <div onClick={onSubmit} className={getCommentButtonClassName()}>
                {isSending && <Spinner />}
                {!isSending && <Icon className="transition" name="sendTransparent" />}
            </div>
        );
    }

    function getInputPlaceholder(): string {
        const { placeholder = '' } = props;
        return placeholder || t('quickCommentInput.placeholder');
    }

    function renderTextarea() {
        const _props: TextareaInputProps = {
            value,
            onChange,
            placeholder: getInputPlaceholder(),
            maxLength: MAX_CHARS_IN_COMMENT,
            className: 'pr-2 flex-1 resize-none bg-transparent dark:bg-transparent px-0 border-none py-1',
            enableAutosize: true,
            rows: 1
        };

        return <TextareaInput {..._props} />;
    }

    return (
        <div className={getClassName()} onClick={onClick}>
            {renderTextarea()}
            {renderCommentButton()}
        </div>
    );
};

export default memo(QuickCommentInput);
