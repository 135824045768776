import React, { FC, memo, useCallback } from 'react';
import { useStoreState } from '@src/model/hooks';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { getTokenLogo } from '@src/model/token/helpers';
import Image, { ImageProps } from '@creator/ui/components/Image/Image';
import Text from '@creator/ui/components/Text/Text';
import { cn } from '@creator/ui/utils/ui';
import { useHistory } from 'react-router-dom';
import { ensureTokenUrl, getDomainConfigValue } from '@src/model/config/helpers';

export interface TokenLogoProps {
    className?: string;
    tokenName: string;
    isLoading?: boolean;
}

const TokenLogo: FC<TokenLogoProps> = props => {
    const { tokenName, className, isLoading } = props;

    const token = useStoreState(state => state.token.getToken(tokenName), storeEqualityFn);
    const src = getImageSrc();
    const history = useHistory();

    const onClick = useCallback(() => {
        const defaultTokenName = getDomainConfigValue('defaultTokenName');
        if (defaultTokenName === tokenName) return history.push('/');
        history.push(`/${ensureTokenUrl(tokenName)}`)
    }, [tokenName]);

    function getImageProps(): ImageProps {
        return {
            src,
            className: cn('flex-shrink-0 w-auto', className),
            isLoading,
            onClick
        };
    }

    function getImageSrc(): string {
        if (token && !token.logoImageURI) return null;
        if (token && token.logoImageURI) return token.logoImageURI;

        return getTokenLogo(tokenName);
    }

    if (!src) return <Text onClick={onClick} className={cn('font-medium cursor-pointer content-center', className)}>{token?.title}</Text>;

    return (
        <Image {...getImageProps()} />
    );
};

export default memo(TokenLogo);
