import React, { FC, useState, useEffect, memo } from 'react';
import { useStoreState, useStoreActions } from '@src/model/hooks';
import Spinner from '@creator/ui/components/Spinner/Spinner';
import { storeEqualityFn } from '@src/utils/object-utils/object-utils';
import { useHistory } from 'react-router-dom';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import { isResponsePost } from '@src/model/upvote/helpers';
import TopCenterAd from './TopCenterAd'; // Import the extracted component
import { cn } from '@creator/ui/utils/ui';
import PostView from '@src/components/Post/PostView/PostView';
import usePageTitle from '@src/hooks/usePageTitle';
import TokenSidebar from '@src/components/Token/TokenSidebar/TokenSidebar';
import { getUserId } from '@src/model/user/helpers';
import { postView } from '@src/store/posts/posts';
import BtmStickyAd from '../BtmStickyAd';
export interface PostTabProps {
    tokenName: string;
    identifier: string;
    commentId?: string;
    replyId?: string;
    className?: string;
}

const PostTab: FC<PostTabProps> = props => {
    const { tokenName, identifier = '', commentId = '', replyId = '', className = '' } = props;

    const postSlugOrId = identifier;
    const loadPostByIdOrSlugUrl = useStoreActions(actions => actions.upvote.loadPostByIdOrSlugUrl);
    const loadResponse = useStoreActions(actions => actions.response.loadResponse);
    const loadTokenAbout = useStoreActions(actions => actions.token.loadTokenAbout);
    const [isLoadingPost, setIsLoadingPost] = useState(false);
    const [isLoadingResponse, setIsLoadingResponse] = useState(false);
    const post = useStoreState(state => state.upvote.getPostBySlugUrlOrId(postSlugOrId), storeEqualityFn);
    usePageTitle(getPageTitle());
    const postId = post?.id;

    const history = useHistory();

    function getPageTitle() {
        if (!post) return '';
        if (post.createdByWhitelistedUser) return post.title;
        return `${post.title} - Community Post`;
    }

    useEffect(() => {
        if (!postId) return;
        postView({ postId, tokenName, userId: getUserId() });
    }, [postId]);


    useEffect(() => {
        if (!postSlugOrId) return;
        loadContent();
        loadTokenAbout(tokenName);
    }, [postSlugOrId]);

    async function loadContent(): Promise<void> {
        const post = await _loadPost();
        if (!post || !isResponsePost(post)) return;
        _loadResponse(post.id);
    }

    async function _loadPost(): Promise<Post | void> {
        try {
            setIsLoadingPost(true);
            const post = await loadPostByIdOrSlugUrl(postSlugOrId);
            setIsLoadingPost(false);
            console.log('postId', post.id);
            return post;
        } catch (e: any) {
            setIsLoadingPost(false);
            if (e.message.includes('not found')) history.push('/bbs/404');
        }
    }

    async function _loadResponse(responseId: string): Promise<void> {
        try {
            setIsLoadingResponse(true);
            await loadResponse(responseId);
        } catch (e: any) {
            console.error(e);
        } finally {
            setIsLoadingResponse(false);
        }
    }

    function renderPost() {
        if (!post) return null;
        return (
            <PostView
                showRecommendedPostList={true}
                loadCommentId={commentId}
                loadReplyId={replyId}
                tokenName={tokenName}
                className=""
                postId={post.id}
            />
        );
    }

    function renderSpinner() {
        if (isLoadingPost && !post) return <Spinner className="mx-auto mt-8" />;
        return null;
    }

    return (
        <div className={cn('space-y-4 pb-4', className)}>
            <TopCenterAd tokenName={tokenName} variant="mobile" />
            <TopCenterAd tokenName={tokenName} variant="desktop" />
            <div className="container flex lg:space-x-4">
                <div className="hidden xl:block xl:w-1/12" />
                <div className="w-full lg:w-8/12 xl:w-7/12">
                    {renderPost()}
                    {renderSpinner()}
                </div>
                <div className="hidden lg:flex lg:w-4/12 xl:w-3/12">
                    <TokenSidebar tokenName={tokenName} />
                </div>
                <div className="hidden xl:block xl:w-1/12" />
            </div>
            <BtmStickyAd tokenName={tokenName} variant="mobile" />
            <BtmStickyAd tokenName={tokenName} variant="desktop" />
        </div>
    );
};

export default memo(PostTab);
