import React, { Suspense, useRef, ReactNode, FC, HTMLAttributes, memo } from "react";
import { useIntersectionObserver } from "@src/hooks/useIntersectionObserver";
import { cn } from "../../utils/ui";
import Spinner from "../Spinner/Spinner";

interface RenderOnViewportEntryProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    className?: string;
}

const RenderOnViewportEntry: FC<RenderOnViewportEntryProps> = ({ children, className = '', }) => {
    const ref = useRef<HTMLDivElement>(null);
    // Adjust the signature below if your hook expects different arguments
    const { inView } = useIntersectionObserver(ref, { threshold: 0, rootMargin: '0px 0px 500px 0px', triggerOnce: true });

    return (
        <div className={cn('', className)} ref={ref}>
            {inView && <Suspense fallback={<Spinner className="mx-auto my-2" />}>{children}</Suspense>}
        </div>
    );
};

export default memo(RenderOnViewportEntry);
