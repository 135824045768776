import { useAtom, useSetAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { fetchPaginatedPosts, getPaginatedPostCollectionAtoms, PaginatedPostCllectionType } from '../posts';
import { useStoreActions } from '@src/model/hooks';
import { Post } from '@creator/sdk/modules/upvote/upvote.model';
import { doc, DocumentData, DocumentSnapshot, getDoc, getFirestore } from 'firebase/firestore/lite';
import creatorSdk from '@src/services/creator-sdk';

export interface UsePaginatedPostsPayload {
    tokenName: string;
    fetchLimit?: number;
    flairId?: string; // Default to 'all' if undefined
    type: PaginatedPostCllectionType;
    orderByField?: keyof Post;
}

export const usePaginatedPosts = (payload: UsePaginatedPostsPayload) => {
    const { tokenName, fetchLimit = 20, flairId, type, orderByField = 'createdAt' } = payload;

    const paginatedAtoms = getPaginatedPostCollectionAtoms(type);
    if (!paginatedAtoms) throw new Error(`Could not find paginated collection for type ${type}`);

    // Create a composite key using tokenName and flairId (default to 'all' if flairId is undefined)
    const compositeKey = `${tokenName}-${flairId || 'all'}`;

    const [paginatedPostIds] = useAtom(useMemo(() => paginatedAtoms.getIds(compositeKey), [compositeKey]));
    const [paginatedLastDoc] = useAtom(useMemo(() => paginatedAtoms.getLastDoc(compositeKey), [compositeKey]));
    const [hasMore] = useAtom(useMemo(() => paginatedAtoms.getHasMore(compositeKey), [compositeKey]));

    const appendPostsIds = useSetAtom(paginatedAtoms.appendIds);
    const setLastDoc = useSetAtom(paginatedAtoms.setLastDoc);
    const setHasMore = useSetAtom(paginatedAtoms.setHasMore);

    // Define state for loading status and error tracking
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [error, setError] = useState<Error | null>(null);

    const setPostsRedux = useStoreActions(actions => actions.upvote.setPosts);

    const loadMore = async () => {
        setStatus('loading');
        setError(null);
        try {
            let lowerBound = paginatedLastDoc;
            if (typeof lowerBound === 'string') {
                // If the last doc is a string, it's a post ID, so we need to fetch the actual document to convert it to a DocumentSnapshot
                const db = getFirestore(creatorSdk.getApp());
                const lastDocRef = doc(db, 'Post', lowerBound);
                lowerBound = await getDoc(lastDocRef) as DocumentSnapshot<Post, DocumentData>;
            }
            // Use the last document for the current composite key
            const { ids, postsData, lastDoc, hasMore } = await fetchPaginatedPosts(type)({
                tokenName,
                fetchLimit,
                flairId,
                lowerBound,
                orderByField
            });

            // Update the posts IDs and last doc for this composite key
            appendPostsIds({ key: compositeKey, ids });
            setLastDoc({ key: compositeKey, lastDoc });
            setHasMore({ key: compositeKey, hasMore });

            // For backward compatibility, also update Redux store
            setPostsRedux(postsData);

            setStatus('success');
            return hasMore;
        } catch (err) {
            setError(err as Error);
            setStatus('error');
            return false;
        }
    };

    // Return the data specific to this composite key along with loading status and error state
    return {
        postIds: paginatedPostIds,
        lastDoc: paginatedLastDoc,
        loadMore,
        hasMore,
        status,
        error,
    };
};
